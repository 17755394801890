import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { text1s } from '../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'login.component',
	templateUrl: 'login.component.html',
	styleUrls: ['../../styles.css']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	loading = false;
	submitted = false;
	hide = true;
	returnUrl: string;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private alertService: AlertService,
		private titleService: Title,
		private metaTagService: Meta,
	) { }

	ngOnInit() {
		this.check();
		this.returnUrl = decodeURIComponent('c/admin/seller-dashboard');
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required]
		});
	}

	async check() {
		const user = await this.authenticationService.getUser();
		const session = localStorage.getItem( window.location.hostname);
		let loggedIn = !!user;
		if (!loggedIn || !session) {

		} else {
			this.router.navigateByUrl('');
		}
	}

	get f() { return this.loginForm.controls; }

	async onSubmit() {
		this.submitted = true;

		this.alertService.clear();

		if (this.loginForm.invalid) {
			return;
		}
		this.loading = true;
		await this.authenticationService.signInRegular(this.f.email.value, this.f.password.value)
			.then(
				async data => {
					if (data) {
						setTimeout(() => {
							this.router.navigateByUrl(this.returnUrl);
						}, 4000);
					} else {
						this.alertService.snackbar(text1s.erro0001);
						this.loading = false;
					}
				}).catch((err) => {
					this.alertService.snackbar(text1s.erro0001);
					this.loading = false;
				});
	}


	clickCrtSellerAccount() {
		this.router.navigate(['a/registerseller'], { queryParams: { returnUrl: this.returnUrl } });
	}

	clickPwReset() {
		this.router.navigate(['a/passwordreset'], { queryParams: { returnUrl: this.returnUrl } });
	}

	setMeta(): void {
		this.titleService.setTitle(`${text1s.domain} | Anmelden`);

		this.metaTagService.updateTag(
			{ name: 'robots', content: 'noindex, nofollow' }
		);
		this.metaTagService.updateTag(
			{ name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
		);
		this.metaTagService.updateTag(
			{ name: 'keywords', content: `${text1s.domain} Anmelden` }
		);
		this.metaTagService.updateTag(
			{ name: 'description', content: `${text1s.domain} Anmelden` }
		);
	}
}