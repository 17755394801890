import { Injectable } from '@angular/core';
import { throwError as _throw, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Busine } from '../models/busine';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { systemSettings } from '../../environments/environment';
import { UUID } from 'angular2-uuid';

@Injectable({ providedIn: 'root' })
export class BusineService {

    objs: AngularFirestoreCollection<Busine>;
    obj: AngularFirestoreDocument<Busine>;
    selectedObj: Busine;

    constructor(
        private firestore: AngularFirestore,
    ) { }

    setSelected(o: Busine) {
        this.selectedObj = o;
    }

    getSelected() {
        return of(this.selectedObj);
    }

    mapDataToBusineModel(_data: AngularFirestoreCollection<Busine>) {
        return _data.snapshotChanges().pipe(map(changes => changes
            .map(c => ({
                doc_id: c.payload.doc.id,
                data_key: c.payload.doc.data().data_key,
                name: c.payload.doc.data().name,
                businename: c.payload.doc.data().businename,
                address: c.payload.doc.data().address,
                zipcode: c.payload.doc.data().zipcode,
                email: c.payload.doc.data().email,
                telefon: c.payload.doc.data().telefon,
                text: c.payload.doc.data().text,
                isActive: c.payload.doc.data().isActive,
                seller_uid: c.payload.doc.data().seller_uid,
                doc_timestamp: c.payload.doc.data().doc_timestamp,
            }))));
    }

    find(data_key: string) {
        return this.mapDataToBusineModel(
            this.firestore.collection(`${systemSettings.collectionPrefix}_busines`, ref => ref.where('data_key', '==', data_key))
        );
    }

    save(data: any) {
        data.doc_timestamp = new Date();
        if (!data?.doc_id) {
            return this.add(data);
        } else {
            this.firestore
                .collection(`${systemSettings.collectionPrefix}_busines`)
                .doc(data.doc_id)
                .update(data);
        }

    };

    private add(data: any) {
        data.data_key = UUID.UUID();
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_busines`)
            .add(data);
        this.sendmail(data);
        return data;
    };

    delete(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_busines`)
            .doc(data.doc_id)
            .delete();
    }

    sendmail(busine: Busine) {
        if (busine) {
            this.firestore
                .collection("trigger_emails")
                .add(
                    {
                        to: busine.email,
                        message: {
                            subject: `Digitale Lösungen für Gastronomie`,
                            html: `<p>Herzlich Willkommen!</p>` +
                            `<p>Vielen Dank für Ihr Interesse an der Zusammenarbeit mit uns.</p>` + 
                            `<p>Sie haben sich richtig entschieden mit uns durchzustarten.</p>` + 
                            `<p>Bauen Sie Ihr Business auf und nutzen Sie Ihre Vorteile – alles mit einer einzigen intelligenten Plattform.</p>` +
                            '<p>Wir kontaktieren Sie umgehend, um eine Lösung zu finden, die zu Ihnen und Ihrer Situation passt.</p>' +
                            '<p>**</p>' +
                            '<p>restaurant.jetzt</p>' +
                            '<p>--</p>' +
                            '<p><small>Diese E-Mail enthält vertrauliche und/oder rechtlich geschützte Informationen. Wenn Sie nicht der richtige Adressat sind, oder diese E-Mail irrtümlich erhalten haben, informieren Sie bitte den Absender und löschen Sie diese Mail. Das unerlaubte Kopieren sowie die unbefugte Weitergabe dieser E-Mail und der darin enthaltenen Informationen sind nicht gestattet.</small></p>'
                        },
                    }
                );

            this.firestore
                .collection("trigger_emails")
                .add(
                    {
                        to: 'mein@restaurant.jetzt',
                        message: {
                            subject: `Kundeinfo: ${busine.name}`,
                            html: `<p>Name: ${busine.name}</p>` +
                            `<p>Firma: ${busine.businename}</p>` +
                            `<p>Address: ${busine.address}</p>` +
                            `<p>PLZ: ${busine.zipcode}</p>` +
                            `<p>Email: ${busine.email}</p>` +
                            `<p>Telefon: ${busine.telefon}</p>` +
                            `<p>Text: ${busine.text}</p>` +
                            `<p>Anmeldung: <a href="https://restaurant.jetzt/a/registerseller/${busine.data_key}">${busine.data_key}</a></p>` +
                            '<p>**</p>' +
                            '<p>Genial bequem & einfach</p>' +
                            '<p>restaurant.jetzt</p>' +
                            '<p>--</p>' +
                            '<p><small>Diese E-Mail enthält vertrauliche und/oder rechtlich geschützte Informationen. Wenn Sie nicht der richtige Adressat sind, oder diese E-Mail irrtümlich erhalten haben, informieren Sie bitte den Absender und löschen Sie diese Mail. Das unerlaubte Kopieren sowie die unbefugte Weitergabe dieser E-Mail und der darin enthaltenen Informationen sind nicht gestattet.</small></p>'
                        },
                    }
                );

        }

    };

}