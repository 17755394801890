import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Seller } from './../models/seller';

export default class CommonFunctions {

    static getDayjs(date?: Date) {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        if (date) {
            return dayjs(date).tz("Europe/Berlin");
        }
        return dayjs().tz("Europe/Berlin");
    }

    static getUnixTimestamp(seconds){
        return dayjs.unix(seconds);
    }

    static getBussinessHours(seller: Seller, iDate?, min_duration?) {

        let openings = new Array();
        if (!iDate) {
            iDate = new Date();
        }
        if (!min_duration) {
            min_duration = 1;
        }
        
        for (let open of seller.openings) {
            if (open.day == this.getDayjs(iDate).day().toString()) {
                let _minute = open.from.minute;
                let endhour = Number(open.to.hour);
                let current_hour = this.getDayjs().hour();
                let current_minute = this.getDayjs().minute();
                if (this.getDayjs(iDate).isAfter(dayjs(new Date()))){
                    current_hour = Number(open.from.hour) + Math.trunc(Number(min_duration)/60);
                    current_minute = Number(open.from.minute) +  Number(min_duration) % 60;
                } else {
                    let start_with_duration_minute = ((Number(open.from.hour) - Number(current_hour)) * 60) - Number(current_minute);
                    if (start_with_duration_minute >= 0) {
                        start_with_duration_minute = start_with_duration_minute + Number(open.from.minute) + Number(min_duration);
                    } else {
                        start_with_duration_minute = Number(min_duration);
                    }
                    if (start_with_duration_minute > 0) {
                        current_hour = this.getDayjs().add(start_with_duration_minute, 'minute').hour();
                        current_minute = this.getDayjs().add(start_with_duration_minute, 'minute').minute();
                    }
                }
               
                for (let starthour = Number(open.from.hour); starthour <= endhour;) {
                        
                        if (starthour >= endhour && _minute >= open.to.minute) {
                            break;
                        }
                        if (starthour > current_hour || (starthour == current_hour && Number(_minute) >= current_minute)) {
                            openings.push({hour: String(starthour).padStart(2, '00'), minute: String(_minute).padStart(2, '00')});
                        }
                        switch(_minute) {
                            case '45':{
                                starthour++;
                                _minute = '00';
                                break;
                            }
                            case '30':{
                                _minute = '45';
                                break;
                            }
                            case '15':{
                                _minute = '30';
                                break;
                            }
                            default: {
                                _minute = '15';
                                break;
                            }
                        }

                    if (starthour == endhour && _minute == open.to.minute && starthour >= current_hour) {
                        openings.push({hour: String(starthour).padStart(2, '00'), minute: String(_minute).padStart(2, '00')});
                    }
                }
            }
        }

        return openings;

    }

    static isBussinessHours(seller: Seller, iDate?) {

        if (!seller?.openings) {
            return false;
        }
        
        let current_day = this.getDayjs().day();
        let current_hour = this.getDayjs().hour();
        let current_minute = this.getDayjs().minute();

        if( iDate ) {
            try {
                current_day = this.getDayjs(iDate.toDate()).day();
                current_hour = this.getDayjs(iDate.toDate()).hour();
                current_minute = this.getDayjs(iDate.toDate()).minute();
            } catch (err) {
                current_day = this.getDayjs(iDate).day();
                current_hour = this.getDayjs(iDate).hour();
                current_minute = this.getDayjs(iDate).minute();
            }
        } 

        const openings = seller.openings.filter(o => Number(o.day) === current_day);

        for (let open of openings) {
            if (current_hour >= Number(open.from.hour)
                && current_hour <= Number(open.to.hour)
            ) {
                if (current_hour == Number(open.from.hour)) {
                    return !(current_minute < Number(open.from.minute));
                }
                if (current_hour == Number(open.to.hour)) {
                    return !(current_minute > Number(open.to.minute));
                }
                return true
            }
        }
        return false;
    }

    static getCookie(cName: string) {
        const name = cName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }


    static setCookie(cName: string, cValue: string, day?: number) {
        if (!day) {
            day = 99;
        }
        var expires_date = new Date();
        var seven_days = expires_date.getTime() + (day * 24 * 60 * 60 * 1000);
        expires_date.setTime(seven_days);

        document.cookie = (cName + '=' + cValue + ';path=/; expires=' + expires_date.toUTCString());
    }

    static deleteCookie(cName: string) {
        document.cookie = (cName + '=' + '' + ';path=/;' + 'expires=Thu, 01-Jan-01 00:00:01 GMT;');
    }

    static setTimestampToDate(timestamp) {
        try {
            return timestamp.toDate();
        } catch (error) {
            // console.log(error);
            return null;
        }
    }

    static convertBase64ToFile(data, filename) {

        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static setUndefinedToNull(data): any {
        for (let o of Object.keys(data)) {
            if (typeof data[o] === 'undefined') {
                data[o] = null;
            }
        }

        return data;
    }

}