import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { AlertService } from '../../services/alert.service';

@Component({ selector: 'alertComponent', templateUrl: 'alert.component.html' })
export class AlertComponent implements OnInit, OnDestroy {
    
    @AutoUnsubscribe()
    subscription$: Subscription;
    message: any;

    constructor(private alertService: AlertService, private _snackBar: MatSnackBar) { }

    ngOnInit() {
        this.subscription$ = this.alertService.getAlert()
            .subscribe(message => {
                switch (message && message.type) {
                    case 'success':
                        message.cssClass = 'alert alert-success';
                        this.message = message;
                        break;
                    case 'error':
                        message.cssClass = 'alert alert-danger';
                        this.message = message;
                        break;
                    case 'snackbar':
                        this.openSnackBar(message.text);
                        break;
                }
            });
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, 'Ok', {
            duration: 3000,
        });
    }

    ngOnDestroy() {
       
    }
}
