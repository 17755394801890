import { environment, systemSettings } from './../environments/environment';
import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UrlSafePipe } from './pipes/url-safe.pipe';
import { AlertComponent } from './components/basic/alert.component';
import { CarouselStartComponent } from './components/basic/carousel-start.component';
import { ErrorInterceptor } from './services/error.interceptor';
import { LoginComponent } from './components/basic/login.component';
import { PasswordResetComponent } from './components/basic/passwordReset.component';
import { RegisterSellerComponent } from './components/basic/registerSeller.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from './material.module';
import { AppComponent } from './app.component';
import { BasicComponent } from './components/basic/basic.component';
import { LogoutComponent } from './components/basic/logout.component';
import { FireBaseConfig } from "../environments/firebase.config";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database/";
import { AngularFireFunctionsModule, USE_EMULATOR } from '@angular/fire/compat/functions';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AsyncPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import { ErrorStateMatcher } from '@angular/material/core';
import { WtErrorStateMatcher } from './services/error.interceptor';
import { SharedModule } from './shared/module/shared.module';

const modules = [
  CommonModule,
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  AppRoutingModule,
  AngularFireModule.initializeApp(FireBaseConfig),
  AngularFireDatabaseModule,
  AngularFireFunctionsModule,
  AngularFirestoreModule,
  AngularFireAuthModule,
  AngularMaterialModule,
  HttpClientModule,
  HttpClientJsonpModule,
  RecaptchaV3Module,
  SharedModule
];

@NgModule({
  declarations: [
    UrlSafePipe,
    AlertComponent,
    AppComponent,
    BasicComponent,
    CarouselStartComponent,
    LoginComponent,
    PasswordResetComponent,
    RegisterSellerComponent,
    LogoutComponent,
  ],
  imports: [modules,],
  exports: [modules,],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [AsyncPipe,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorStateMatcher, useClass: WtErrorStateMatcher },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: systemSettings.recaptcha_id },
    { provide: USE_EMULATOR, useValue: environment.production ? undefined : ['localhost', 5001] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
