import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { throwError as _throw, of } from 'rxjs';
import { Table } from '../models/table';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AuthenticationService } from './authentication.service';
import { User } from '../models/user';
import { systemSettings } from '../../environments/environment';
import { UUID } from 'angular2-uuid';
import CommonFunctions from "../shared/common.functions";

@Injectable({ providedIn: 'root' })
export class TableService {

    selectedObj: Table;
    private user: User;

    constructor(
        private firestore: AngularFirestore,
        private authService: AuthenticationService,
    ) {
        this.authService.getCurrentUser().subscribe(user => this.user = user);
    }

    setSelected(o: Table) {
        this.selectedObj = o;
    }

    getSelected() {
        return of(this.selectedObj);
    }

    findTableName(tablename: string) {
        return this.mapDataToObj(this.firestore.collection(`${systemSettings.collectionPrefix}_tables`, ref => ref.where('tablename', '==', tablename)));  
    }

    mapDataToObj(data: AngularFirestoreCollection<Table>) {
        return data.snapshotChanges().pipe(map(changes => changes
            .map(c => ({
                doc_id: c.payload.doc.id,
                data_key: c.payload.doc.data().data_key,
                nr: c.payload.doc.data().nr,
                tablename: c.payload.doc.data().tablename,
                place: c.payload.doc.data().place,
                state: c.payload.doc.data().state,
                option: c.payload.doc.data().option,
                selforder_status: c.payload.doc.data().selforder_status,
                seller_uid: c.payload.doc.data().seller_uid,
                reservable: c.payload.doc.data().reservable,
                doc_timestamp: c.payload.doc.data().doc_timestamp,
            })))
        );
    }

    findUID(uid: string) {
        if (uid == '') {
            uid = this.user.uid;
        }
        return this.mapDataToObj(this.firestore.collection(`${systemSettings.collectionPrefix}_tables`, ref => ref.where('seller_uid', '==', uid).orderBy("selforder_status").orderBy("nr")));
    }

    findUIDOrderByPlace(uid: string) {
        if (uid == '') {
            uid = this.user.uid;
        }
        return this.mapDataToObj(this.firestore.collection(`${systemSettings.collectionPrefix}_tables`, ref => ref
        .where('seller_uid', '==', uid)
        .where('reservable', '==', true)
        .orderBy("place")
        .orderBy("nr")));
    }

    save(data: any) {
        this.user = this.authService.getCurrentUser().getValue();
        if (!data?.seller_uid && this.user?.uid) {
            data.seller_uid = this.user.uid;
        }
        data = CommonFunctions.setUndefinedToNull(data);
        if(!data.selforder_status){
            data.selforder_status = 'zz';
        }
        data.doc_timestamp = new Date();
        if (!data?.doc_id) {
            this.add(data);
        } else {
            this.firestore
                .collection(`${systemSettings.collectionPrefix}_tables`)
                .doc(data.doc_id)
                .update(data);
        }

    };

    private add(data: any) {
        data.data_key = UUID.UUID();
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_tables`)
            .add(data);
    };

    delete(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_tables`)
            .doc(data.doc_id)
            .delete();
    }
}