import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimerComponent } from '../timer.component';
import { ShowinfoComponent } from '../showinfo.component';
import { AngularMaterialModule } from './../../material.module';
import { CountdownModule } from 'ngx-countdown';
import { NavbarComponent } from '../navbar.component';

@NgModule({
  declarations: [
    TimerComponent,
    ShowinfoComponent,
    NavbarComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    CountdownModule
  ],
  exports: [
    TimerComponent,
    ShowinfoComponent,
    NavbarComponent
  ],
  providers: [],
})
export class SharedModule { }
