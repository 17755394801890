export const FireBaseConfig = {
  apiKey: "AIzaSyCYa5z8xJEPtNiay43qKncGoHP8_12R_QM",
  authDomain: "foodservice-city.firebaseapp.com",
  databaseURL: "https://foodservice-city.firebaseio.com",
  projectId: "foodservice-city",
  storageBucket: "foodservice-city.appspot.com",
  messagingSenderId: "366059414934",
  appId: "1:366059414934:web:bb58fdd27eb1c76007e9b7",
  measurementId: "G-1LRGQ2TC7F"
};
