import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { throwError as _throw, of } from 'rxjs';
import { Order } from '../models/order';
import { Seller } from '../models/seller';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { systemSettings } from '../../environments/environment';
import { UUID } from 'angular2-uuid';
import CommonFunctions from "../shared/common.functions";

@Injectable({ providedIn: 'root' })
export class OrderService {

    selectedObjs: Order[] = new Array();
    private customer_address: any;
    public data_sharing: any;
    counter = 0;

    constructor(
        private firestore: AngularFirestore,
        private fireFunctions: AngularFireFunctions,
    ) {}

    setCustomerAddress(o: any, isAllowSave: boolean) {
        this.customer_address = o;
        if (isAllowSave) {
            CommonFunctions.setCookie(systemSettings.cookieNameCustomerAddress, JSON.stringify(this.customer_address));
        } else {
            CommonFunctions.deleteCookie(systemSettings.cookieNameCustomerAddress);
        }
    }

    getCustomerAddress(isFromCookie?) {
        if (!this.customer_address || isFromCookie) {
            try {
                this.customer_address = JSON.parse(CommonFunctions.getCookie(systemSettings.cookieNameCustomerAddress));
            } catch (error) {
                this.customer_address = null;
            }
        }
        return this.customer_address;
    }

    removeSelected(o: Order) {
        const iOrder = this.selectedObjs.findIndex(val => val.seller_uid === o.seller_uid);
        if (iOrder > -1) {
            this.selectedObjs.splice(iOrder, 1);
        }
        //CommonFunctions.deleteCookie(systemSettings.cookieNameCustomerOrders);
        localStorage.removeItem(systemSettings.cookieNameCustomerOrders);
    }

    setSelected(o: Order) {
        const iOrder = this.selectedObjs.findIndex(val => val.seller_uid === o.seller_uid);
        if (iOrder > -1) {
            this.selectedObjs[iOrder] = o;
        } else {
            this.selectedObjs.push(o);
        }
        //CommonFunctions.setCookie(systemSettings.cookieNameCustomerOrders, JSON.stringify(this.selectedObjs));
        localStorage.setItem(systemSettings.cookieNameCustomerOrders, JSON.stringify(this.selectedObjs));
    }

    getSelected(seller_uid: string) {
        this.getOrderCookie();
        let result = {} as Order;
        const iOrder = this.selectedObjs.findIndex(val => val.seller_uid === seller_uid);
        if (iOrder > -1) {
            result = this.selectedObjs[iOrder];
        }
        return of(result);
    }

    getOrderCookie() {
        try {
            //this.selectedObjs = JSON.parse(CommonFunctions.getCookie(systemSettings.cookieNameCustomerOrders));
            this.selectedObjs = JSON.parse(localStorage.getItem(systemSettings.cookieNameCustomerOrders));
            if (!this.selectedObjs) {
                this.selectedObjs = new Array();
            }
        } catch (error) {
            console.log(error)
        }
    }

    getCounter(strDateSellerUid: any){
        this.fireFunctions.httpsCallable('get_order_nr')(strDateSellerUid)
        .subscribe(result => {
            this.counter = result + 1;
        });
    }

    findSellerUID(seller_uid: string, strDate: Date, endDate: Date) {
        if (!strDate || !endDate) {
            strDate = new Date();
            endDate = new Date();
        }
        if (strDate.getDate() === endDate.getDate()) {
            endDate.setDate(strDate.getDate() + 1);
        }
        strDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        if (seller_uid) {
            return this.mapDataToOrderModel(
                this.firestore.collection(`${systemSettings.collectionPrefix}_orders`, ref => ref
                    .where('seller_uid', '==', seller_uid)
                    .where('orderday', '>=', strDate)
                    .where('orderday', '<=', endDate)
                    .orderBy('orderday', 'desc')
                    .orderBy('order_nr', 'desc')
                )
            );
        }
        return of(null);
    }

    findDataKey(data_key: string) {
        if (data_key) {
            return this.mapDataToOrderModel(
                this.firestore.collection(`${systemSettings.collectionPrefix}_orders`, ref => ref.where('data_key', '==', data_key).orderBy('doc_timestamp', 'desc'))
            );
        }
        return of(null);
    }

    mapDataToOrderModel(_data: AngularFirestoreCollection<Order>) {
        return _data.snapshotChanges().pipe(map(changes => changes
            .map(c => ({
                doc_id: c.payload.doc.id,
                data_key: c.payload.doc.data().data_key,
                deliverer: c.payload.doc.data().deliverer,
                customer: c.payload.doc.data().customer,
                text: c.payload.doc.data().text,
                payment_option: c.payload.doc.data().payment_option,
                payment_status: c.payload.doc.data().payment_status,
                shipment_option: c.payload.doc.data().shipment_option,
                duration_option: c.payload.doc.data().duration_option,
                orderArticles: c.payload.doc.data().orderArticles,
                additional_costs: c.payload.doc.data().additional_costs,
                totalPrice: c.payload.doc.data().totalPrice,
                city: c.payload.doc.data().city,
                zipcode: c.payload.doc.data().zipcode,
                status: c.payload.doc.data().status,
                seller_uid: c.payload.doc.data().seller_uid,
                order_nr: c.payload.doc.data().order_nr,
                bill_nr: c.payload.doc.data().bill_nr,
                taxs: c.payload.doc.data().taxs,
                orderday: c.payload.doc.data().orderday,
                ordertime: c.payload.doc.data().ordertime,
                doc_timestamp: c.payload.doc.data().doc_timestamp,
            }))));
    }

    save(data: any) {
        data = CommonFunctions.setUndefinedToNull(data);
        data.customer.authcode = '******';
        if (!data.order_nr) {
            data.order_nr = (this.counter + 150).toString();
        }
        if (!data.orderday) {
            data.orderday = new Date();
        }
        if (data.status === "s3") {
            data.doc_timestamp = new Date();
        }
        if (!data?.doc_id) {
            data = this.add(data);
        } else {
            this.firestore
                .collection(`${systemSettings.collectionPrefix}_orders`)
                .doc(data.doc_id)
                .update(data);
        }
        return data;
    };

    private add(data: any) {
        data.doc_timestamp = new Date();
        data.orderday = new Date(data.orderday);
        data.data_key = UUID.UUID();
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_orders`)
            .add(data);
        return data;
    };

    delete(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_orders`)
            .doc(data.doc_id)
            .delete();
    }

    sendmail(order: Order, seller: Seller) {
        if (order && seller && order?.status) {
            if (order.status === 's2') {
                this.firestore
                    .collection("trigger_emails")
                    .add(
                        {
                            to: order.customer.email,
                            template: {
                                name: 'order_confirm',
                                data: {
                                    orderTable: this.setOrderToHtml(order),
                                    seller: seller,
                                    order: order,
                                    google_review: null,
                                    twitter: seller.webhooks.find(el => el.name === 'twitter')?.value ?? null,
                                    google_bussiness: seller.webhooks.find(el => el.name === 'google_bussiness')?.value ?? null,
                                    facebook: seller.webhooks.find(el => el.name === 'facebook')?.value ?? null,
                                    instagram: seller.webhooks.find(el => el.name === 'instagram')?.value ?? null,
                                    youtube: seller.webhooks.find(el => el.name === 'youtube')?.value ?? null,
                                    pinterest: seller.webhooks.find(el => el.name === 'pinterest')?.value ?? null,
                                    website: seller.webhooks.find(el => el.name === 'website')?.value ?? null,
                                }
                            }
                        }
                    );
            }

            if (order.status === 's7') {
                this.firestore
                    .collection("trigger_emails")
                    .add(
                        {
                            to: order.customer.email,
                            template: {
                                name: 'order_done',
                                data: {
                                    orderTable: this.setOrderToHtml(order),
                                    seller: seller,
                                    order: order,
                                    google_review: seller.webhooks.find(el => el.name === 'google_review')?.value ?? null,
                                    twitter: seller.webhooks.find(el => el.name === 'twitter')?.value ?? null,
                                    google_bussiness: seller.webhooks.find(el => el.name === 'google_bussiness')?.value ?? null,
                                    facebook: seller.webhooks.find(el => el.name === 'facebook')?.value ?? null,
                                    instagram: seller.webhooks.find(el => el.name === 'instagram')?.value ?? null,
                                    youtube: seller.webhooks.find(el => el.name === 'youtube')?.value ?? null,
                                    pinterest: seller.webhooks.find(el => el.name === 'pinterest')?.value ?? null,
                                    website: seller.webhooks.find(el => el.name === 'website')?.value ?? null,
                                }
                            }
                        }
                    );
            }

            if (order.status === 's8') {
                this.firestore
                    .collection("trigger_emails")
                    .add(
                        {
                            to: order.customer.email,
                            template: {
                                name: 'order_feedback',
                                data: {
                                    orderTable: this.setOrderToHtml(order),
                                    seller: seller,
                                    order: order,
                                    google_review: seller.webhooks.find(el => el.name === 'google_review')?.value ?? null,
                                    twitter: seller.webhooks.find(el => el.name === 'twitter')?.value ?? null,
                                    google_bussiness: seller.webhooks.find(el => el.name === 'google_bussiness')?.value ?? null,
                                    facebook: seller.webhooks.find(el => el.name === 'facebook')?.value ?? null,
                                    instagram: seller.webhooks.find(el => el.name === 'instagram')?.value ?? null,
                                    youtube: seller.webhooks.find(el => el.name === 'youtube')?.value ?? null,
                                    pinterest: seller.webhooks.find(el => el.name === 'pinterest')?.value ?? null,
                                    website: seller.webhooks.find(el => el.name === 'website')?.value ?? null,
                                    feedback: seller.webhooks.find(el => el.name === 'feedback')?.value ?? null,
                                    newsletter: seller.webhooks.find(el => el.name === 'newsletter')?.value ?? null,
                                    blog: seller.webhooks.find(el => el.name === 'blog')?.value ?? null,
                                }
                            }
                        }
                    );
            }
        }
    }

    setOrderToHtml(order: Order): string {
        let _orderString = '<table role="presentation" border="0" cellpadding="1" cellspacing="1" width="100%">';

        for (let a of order.orderArticles) {
            _orderString = _orderString + '<tr>';
            _orderString = _orderString + `<td><b>${a.order_quantities}x ${a.articlename}</b> ${a.articleOption.description}</td>`;
            _orderString = _orderString + `<td style="text-align: right;">${a.articleOption.price.toFixed(2)}€</td>`;
            _orderString = _orderString + '</tr>';
        }
        for (let cost of order.additional_costs) {
            _orderString = _orderString + '<tr>';
            _orderString = _orderString + `<td>${cost.name}</td>`;
            _orderString = _orderString + `<td style="text-align: right;">${cost.price.toFixed(2)}€</td>`;
            _orderString = _orderString + '</tr>';
        }
        _orderString = _orderString + '<tr>';
        _orderString = _orderString + `<td></td>`;
        _orderString = _orderString + `<td></td>`;
        _orderString = _orderString + '</tr>';
        _orderString = _orderString + '<tr>';
        _orderString = _orderString + `<td><b>Gesamt</b></td>`;
        _orderString = _orderString + `<td style="text-align: right;"><b>${order.totalPrice.toFixed(2)}€</b></td>`;
        _orderString = _orderString + '</tr>';

        for (let tax of order.taxs) {
            _orderString = _orderString + '<tr>';
            _orderString = _orderString + `<td>MwSt. ${tax.tax}%</td>`;
            _orderString = _orderString + `<td style="text-align: right;">${tax.tax_amount.toFixed(2)}€</td>`;
            _orderString = _orderString + '</tr>';
        }

        _orderString = _orderString + '</table>';

        return _orderString;

    };
}