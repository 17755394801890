import { Document } from './document';

export interface Seller extends Document {
  shopname: string;
  description: string;
  firstname: string;
  lastname: string;
  city: string;
  district: string;
  street: string;
  house: string;
  zipcode: string;
  country: string;
  telefon: string;
  email: string;
  img: string[];
  infotext?: string;
  delivery_areas?: {
    location: string;
    duration_option: string;
    min_order_value: number;
    price: number;
  }[];
  stripe_connect_account_id?: string;
  stripe_accountLinks_url?: string;
  paypal_connect_account_id?: string;
  paypal_accountLinks_url?: string;
  uid: string;
  shopStatus: string;
  searchKeys?: string[];
  payments?: {
    method: string;
    status: boolean;
  }[];
  auto_confirm: {
    isAuto: boolean;
    duration_option: string;
  }
  webhooks?: {
    name: string;
    value: string;
  }[];
  domain: string;
  openings?: {
    day: string;
    from: {hour: string; minute: string;};
    to: {hour: string; minute: string;};
  }[];
  holidays?: {from: Date, to: Date}[];
  capacity: {place: number; minute_reserv: number; minute_person: number}
}

export enum ShopStatuss {
  pos_03 = 'Lieferservice',
  pos_05 = 'Abholservice',
  pos_07 = 'Offline',
  pos_09 = 'Inaktiv'
};

export const Hours = ['08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05', '06', '07',];
export const Minutes = ['00','30'];

export const Days: Record<string, string> =
{
  '0': 'So',
  '1': 'Mo',
  '2': 'Di',
  '3': 'Mi',
  '4': 'Do',
  '5': 'Fr',
  '6': 'Sa',
};
