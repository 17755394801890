import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasicComponent } from './components/basic/basic.component';
import { LogoutComponent } from './components/basic/logout.component';
import { LoginComponent } from './components/basic/login.component';
import { RegisterSellerComponent } from './components/basic/registerSeller.component';
import { PasswordResetComponent } from './components/basic/passwordReset.component';

const routes: Routes = [
  {
    path: '', component: BasicComponent,
    children:
      [
        {
          path: '',
          loadChildren: () => import('./components/start/module/start.module').then(m => m.StartModule)
        },
        {
          path: 'shop',
          loadChildren: () => import('./components/shop/module/shop.module').then(m => m.ShopModule)
        },
      ]
  },
  {
    path: 'a', component: BasicComponent,
    children:
      [
        { path: 'registerseller/:business_id', component: RegisterSellerComponent },
        { path: 'passwordreset', component: PasswordResetComponent },
        { path: 'login', component: LoginComponent },
        { path: '**', redirectTo: '', pathMatch: 'full' }
      ]
  },
  {
    path: 'c', component: BasicComponent,
    children:
      [
        {
          path: 'admin',
          loadChildren: () => import('./components/admin/module/admin.module').then(m => m.AdminModule)
        },
        { path: '**', redirectTo: '', pathMatch: 'full' }
      ]
  },
  {
    path: 'e', component: BasicComponent,
    children:
      [
        { path: 'logout', component: LogoutComponent },
        { path: '**', redirectTo: '', pathMatch: 'full' }
      ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
