import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Seller, Days } from './../models/seller';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./../styles.css']
})
export class NavbarComponent {

    seller: Seller;
    days = Days;

    @Input() set _seller(input) {
        if (input) {
            this.seller = input;
        } else {
            this.seller = null;
        }  
    }

    constructor(
        private router: Router,
    ) {}

    getTelefon() {
        return `Tel:${this.seller.telefon}`;
    }

    clickToNavigate(router: string) {
        this.router.navigate([router]);
    }

    clickToCustomerPage(router: string) {
        this.router.navigate([`${router}${this.seller.zipcode}/${this.seller.city}/${this.seller.shopname}/${this.seller.uid}`]);
    }

}
