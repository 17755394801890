import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { throwError as _throw, of } from 'rxjs';
import { Deliverer } from '../models/deliverer';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthenticationService } from './authentication.service';
import { User } from '../models/user';
import { systemSettings } from '../../environments/environment';
import { UUID } from 'angular2-uuid';

@Injectable({ providedIn: 'root' })
export class DelivererService {

    objs: AngularFirestoreCollection<Deliverer>;
    obj: AngularFirestoreDocument<Deliverer>;
    selectedObj: Deliverer;
    private user: User;

    constructor(
        private firestore: AngularFirestore,
        private authService: AuthenticationService,
    ) {
        this.authService.getCurrentUser().subscribe(user => this.user = user);
    }

    setSelected(o: Deliverer) {
        this.selectedObj = o;
    }

    getSelected() {
        return of(this.selectedObj);
    }

    findUID(uid: string) {
        if (uid == '') {
            uid = this.user.uid;
        }
        this.objs = this.firestore.collection(`${systemSettings.collectionPrefix}_deliverers`, ref => ref.where('seller_uid', '==', uid).orderBy("nr").orderBy("deliverername"));
        return this.objs.snapshotChanges().pipe(map(changes => changes
            .map(c => ({
                doc_id: c.payload.doc.id,
                data_key: c.payload.doc.data().data_key,
                nr: c.payload.doc.data().nr,
                deliverername: c.payload.doc.data().deliverername,
                state: c.payload.doc.data().state,
                seller_shopname: c.payload.doc.data().seller_shopname,
                seller_uid: c.payload.doc.data().seller_uid,
                doc_timestamp: c.payload.doc.data().doc_timestamp,
            }))));
    }

    save(data: any) {
        this.user = this.authService.getCurrentUser().getValue();
        if (this.user?.uid) {
            data.seller_uid = this.user.uid;

        }

        data.doc_timestamp = new Date();
        if (!data?.doc_id) {
            this.add(data);
        } else {
            this.firestore
                .collection(`${systemSettings.collectionPrefix}_deliverers`)
                .doc(data.doc_id)
                .update(data);
        }

    };

    private add(data: any) {
        data.data_key = UUID.UUID();
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_deliverers`)
            .add(data);
    };

    delete(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_deliverers`)
            .doc(data.doc_id)
            .delete();
    }
}