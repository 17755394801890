import { Injectable } from '@angular/core';
import { map, first, take } from 'rxjs/operators';
import { throwError as _throw, of, } from 'rxjs';
import { Seller } from '../models/seller';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthenticationService } from './authentication.service';
import { User } from '../models/user';
import { systemSettings } from '../../environments/environment';
import { UUID } from 'angular2-uuid';
import CommonFunctions from "../shared/common.functions";

@Injectable({ providedIn: 'root' })
export class SellerService {

    objs: AngularFirestoreCollection<Seller>;
    obj: AngularFirestoreDocument<Seller>;
    selectedObj: Seller;
    private user: User;
   
    constructor(
        private firestore: AngularFirestore,
        private authService: AuthenticationService,
    ) {}

    getSellerUid(email: string): Promise<any> {
        this.objs = this.firestore.collection(`${systemSettings.collectionPrefix}_sellers`, ref => ref.where('email', '==', email));
        return this.objs.snapshotChanges().pipe(take(1)).pipe(map(changes => changes
            .map(c => ({
                uid: c.payload.doc.data().uid,
            })))).pipe(first()).toPromise();
    }

    getContentUid() {
        try {
            return JSON.parse(CommonFunctions.getCookie(systemSettings.cookieNameContentUid));
        } catch (error) {
            return '';
        }
    }

    getDeliveryAreas(seller: Seller, zipcode: String) {
        if (!seller?.delivery_areas || seller?.delivery_areas.length < 1) {
            return null;
        }
        return seller.delivery_areas.find(val => val.location === zipcode);
    }

    setContentUid(uid: string) {
        try {
            CommonFunctions.setCookie(systemSettings.cookieNameContentUid, JSON.stringify(uid));
        } catch (error) {

        }
    }

    setSelected(o: Seller) {
        this.selectedObj = o;
    }

    getSelected() {
        return of(this.selectedObj);
    }

    getSeller(uid: String): Promise<any> {
        return this.firestore.collection(`${systemSettings.collectionPrefix}_sellers`, ref => ref.where('uid', '==', uid))
            .snapshotChanges().pipe(first()).toPromise();
    }

    find(uid: String) {
        this.user = this.authService.getCurrentUser().getValue();
        if (uid == '' && this.user?.uid) {
            uid = this.user.uid;
        }
        this.objs = this.firestore.collection(`${systemSettings.collectionPrefix}_sellers`, ref => ref.where('uid', '==', uid));
        return this.objs.snapshotChanges().pipe(map(changes => changes
            .map(c => ({
                doc_id: c.payload.doc.id,
                data_key: c.payload.doc.data().data_key,
                shopname: c.payload.doc.data().shopname,
                description: c.payload.doc.data().description,
                firstname: c.payload.doc.data().firstname,
                lastname: c.payload.doc.data().lastname,
                city: c.payload.doc.data().city,
                district: c.payload.doc.data().district,
                street: c.payload.doc.data().street,
                house: c.payload.doc.data().house,
                zipcode: c.payload.doc.data().zipcode,
                country: c.payload.doc.data().country,
                telefon: c.payload.doc.data().telefon,
                email: c.payload.doc.data().email,
                shopStatus: c.payload.doc.data().shopStatus,
                img: c.payload.doc.data().img,
                infotext: c.payload.doc.data().infotext,
                delivery_areas: c.payload.doc.data().delivery_areas,
                stripe_connect_account_id: c.payload.doc.data().stripe_connect_account_id,
                stripe_accountLinks_url: c.payload.doc.data().stripe_accountLinks_url,
                paypal_connect_account_id: c.payload.doc.data().paypal_connect_account_id,
                paypal_accountLinks_url: c.payload.doc.data().paypal_accountLinks_url,
                doc_timestamp: c.payload.doc.data().doc_timestamp,
                uid: c.payload.doc.data().uid,
                searchKeys: c.payload.doc.data().searchKeys,
                payments: c.payload.doc.data().payments,
                auto_confirm: c.payload.doc.data().auto_confirm,
                webhooks: c.payload.doc.data().webhooks,
                domain: c.payload.doc.data().domain,
                openings: c.payload.doc.data().openings,
                holidays: c.payload.doc.data().holidays || [{from: null, to: null}],
                capacity: c.payload.doc.data().capacity
            }))));
    }

    save(data: any) {
        data = CommonFunctions.setUndefinedToNull(data);
        this.user = this.authService.getCurrentUser().getValue();
        if (this.user?.uid) {
            data.uid = this.user.uid;
        }
        data.doc_timestamp = new Date();
        data.searchKeys = data.delivery_areas?.map(val => val.location) || [''];
        if (!data.searchKeys || data.searchKeys.length < 1) {
            data.searchKeys = new Array();
            data.searchKeys.push(data.city || ['']);
        }
        if (!data?.doc_id) {
            this.add(data);
        } else {
            this.firestore
                .collection(`${systemSettings.collectionPrefix}_sellers`)
                .doc(data.doc_id)
                .update(data);
        }
    };

    private add(data: any) {
        data.data_key = UUID.UUID();
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_sellers`)
            .add(data);
    };

    delete(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_sellers`)
            .doc(data.doc_id)
            .delete();
    }

    sendmail(data: any) {
        this.firestore
            .collection("trigger_emails")
            .add(
                {
                    to: data.email,
                    message: {
                        subject: 'Herzlich Willkommen bei restaurant.jetzt',
                        html: '<p>Hallo ' + data.firstname + ' ' + data.lastname + ', </p>' +
                            '<p> Vielen Dank für Ihre Registrierung!</p>' +
                            '<p> Um den Vorgang abzuschließen klicken Sie bitte auf den Link und folge den Anweisungen:</p>' +
                            '<a href="' + data.stripe_accountLinks_url + '">link text</a>' +
                            '<p>**</p>' +
                            '<p>Genial bequem & einfach</p>' +
                            '<p>restaurant.jetzt</p>' +
                            '<p>--</p>' +
                            '<p><small>Diese E-Mail enthält vertrauliche und/oder rechtlich geschützte Informationen. Wenn Sie nicht der richtige Adressat sind, oder diese E-Mail irrtümlich erhalten haben, informieren Sie bitte den Absender und löschen Sie diese Mail. Das unerlaubte Kopieren sowie die unbefugte Weitergabe dieser E-Mail und der darin enthaltenen Informationen sind nicht gestattet.</small></p>'
                        },
                }
            );
    };
}