import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { SellerService } from '../../services/seller.service';
import { User } from '../../models/user';
import { Seller } from '../../models/seller';
import { Table } from '../../models/table';
import { Order } from '../../models/order';
import { MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { text1s, systemSettings } from '../../../environments/environment';
import CommonFunctions from "../../shared/common.functions";
import { UUID } from 'angular2-uuid';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { OrderService } from '../../services/order.service';
import { TableService } from '../../services/table.service';
import { Showinfo } from '../../models/showinfo';
import { ShowinfoComponent } from '../../shared/showinfo.component';
import { MatDialog } from '@angular/material/dialog';
import { first } from "rxjs/operators";
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';

@Component({
    selector: 'basic',
    templateUrl: 'basic.component.html',
    styleUrls: ['../../styles.css']
})
export class BasicComponent implements OnInit {

    @AutoUnsubscribe()
    orders$;
    @AutoUnsubscribe()
    tables$;
    @AutoUnsubscribe()
    wRouter$;
    token: any = window.location.hostname;
    uid = '';
    currentUser: User;
    seller: Seller;
    sellerAdmin: Seller;
    _systemSettings = systemSettings;
    tables: Table[] = new Array();
    orders: Order[] = new Array();

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private _snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private sellerService: SellerService,
        private cdRef: ChangeDetectorRef,
        private recaptchaV3Service: ReCaptchaV3Service,
        private orderService: OrderService,
        private tableService: TableService,
    ) {
        if (!CommonFunctions.getCookie(systemSettings.cookieName))
            this._snackBar.openFromComponent(BasicInfoComponent, {
                duration: 1200 * 1000,
            });
    }

    async getUid() {
        return await this.sellerService.getSellerUid(systemSettings.operator);
    }

    clickShowPage(router: string, content_group: string) {
        this.router.navigate([router, content_group, this.sellerAdmin.uid]);
    }

    ngOnInit() {
        this.recaptchaV3Service.execute('basic_component').pipe(first()).subscribe((token) => this.initDetails(token));
    }

    callRingOrder(_order){
        const _time = `Um ${_order.ordertime.hour}:${_order.ordertime.minute} Uhr / ${_order.customer.name}`; 
        let showinfo = {
            title: text1s.info0017,
            texts: [_time, _order.text, CommonFunctions.getDayjs(_order.orday).format("DD.MM.YYYY")],
            img: '',
            audio: '../../assets/audio/alarm.wav'
        } as Showinfo;
        this.dialog.open(ShowinfoComponent, {
            width: "390px",
            disableClose: true,
            data: showinfo
        });
    }

    callRingSelfOrder(_table){
        const _text = `Nr. ${_table.nr}`; 
        let showinfo = {
            title: text1s.info0020,
            texts: [_text],
            img: '',
            audio: '../../assets/audio/alarm.wav'
        } as Showinfo;
        this.dialog.open(ShowinfoComponent, {
            width: "390px",
            disableClose: true,
            data: showinfo
        });
    }

    initDetails(token){        
        sessionStorage.setItem('__restaurantjetzt__recaptcha', token); 
        this.getUid().then(seller => {
            this.sellerService.find(seller[0].uid).pipe(first()).subscribe(seller => {
                this.sellerAdmin = seller[0];
                this.sellerService.setContentUid(this.sellerAdmin.uid);
            });
        });
        this.currentUser = this.authenticationService.getCurrentUser().getValue();
        this.uid = CommonFunctions.getCookie(this.token);
        if (this.currentUser.uid != null) {
            this.uid = this.currentUser.uid;
            let enddate = new Date();
            enddate.setDate(enddate.getDate() + 999 );
            this.orders$ = this.orderService.findSellerUID(this.uid, new Date(), enddate).subscribe(orders => {
                this.orders = orders;
                const _order = this.orders.find(o => o.status === 's1');
                if (this.orders && _order) {
                    this.callRingOrder(_order);
                }
            });
            this.tables$ = this.tableService.findUID(this.uid, ).subscribe(tables => {
                this.tables = tables;
                const _table = this.tables.find(o => o.selforder_status === 's1' || o.selforder_status === 's3');
                if (this.tables && _table) {
                    this.callRingSelfOrder(_table);
                } 
            });
        }
        if (this.uid != '') {
            this.sellerService.find(this.uid).pipe(first()).subscribe(
                o => {
                    this.seller = o[0];
                }
            );
        }
        this.wRouter$ = this.router.events
            .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
            .subscribe((event) => {
                window.scrollTo(
                    {
                        top: 0,
                        left: 0,
                        behavior: 'auto'
                    }
                );
            });

        const elm = document.querySelector<HTMLElement>('.grecaptcha-badge')!;
        elm.style.visibility = 'hidden';
    }

    clickToNavigate(router: string) {
        this.router.navigate([router]);
    }

    logout() {
        this.authenticationService.logout();
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

}

@Component({
    selector: 'basic-snackbar-info-component',
    templateUrl: 'basic-snackbar-info.component.html',
    styleUrls: ['../../styles.css'],
})
export class BasicInfoComponent {

    address: any;

    clickShowPageAGB() {
        this.router.navigate([text1s.link_agb1]);
    }

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        public snackBarRef: MatSnackBarRef<BasicInfoComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {
        this.authenticationService.getIPAddress().pipe(first()).subscribe(a => this.address = a);
    }

    setCookie() {
        CommonFunctions.setCookie(systemSettings.cookieName, UUID.UUID());
    }
}
