<main class="mt-1 pt-4">
    <div class="container dark-grey-text mt-5">
        <!--Navbar-->
        <nav class="navbar navbar-expand-lg navbar-dark mdb-color mt-3 mb-1">
            <span class="text-white">
            <a class="nav-link" [routerLink]="['']"><i class="fas fa-home"></i></a>
        </span>
            <!-- Collapse button -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicNav" aria-controls="basicNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
            <!-- Collapsible content -->
            <div class="collapse navbar-collapse" id="basicNav">
                <!-- Links -->
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link" [routerLink]="['../../a/login']"><i class="fas fa-user"></i> Anmelden </a>
                        <span class="sr-only">(current)</span>
                    </li>
                </ul>
            </div>
            <!-- Collapsible content -->
        </nav>
        <!--/.Navbar-->

        <div class="row">
            <div class="col">
                <!--Card-->
                <div class="card">
                    <!--Card content-->
                    <div class="card-body text-center">
                        <p>Vielen Dank!</p>
                    </div>
                </div>
                <!--Card-->
            </div>
        </div>
        <!--row-->
        <hr>
        <articleShowAddInfo-list></articleShowAddInfo-list>
    </div>
</main>