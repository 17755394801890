export const environment = {
  production: true,
};

export const systemSettings: Record<any, any> =
{
  'operator': 'admin24@foodservice.city',
  'email': 'foodservice.city@gmail.com',
  'collectionPrefix': 'foodservice',
  'storagePrefix': 'foodservice',
  'cookieName': '_restaurantjetzt_session',
  'country': 'DE',
  'currency': 'EUR',
  'recaptcha_id' : '6Lf6xIEiAAAAAM5xwJgIL5xpy5kGdvNleIv0bvDD',
  'paypal_connect_account_id': 'AcxqmuLNLVMvTdVNSTuTmy8WhkyK4G1Dk00xKcUYoJHeFj03Vfuji76_mE0GiugKpmpI7khSg9pucjVd',
  'checkEmailVerified': true,
  'cookieNameCustomerAddress': '_restaurantjetzt_customer_address',
  'cookieNameCustomerOrders': '_restaurantjetzt_customer_orders',
  'cookieNameSelfOrders': '_restaurantjetzt_self_orders',
  'cookieNameContentUid': '_restaurantjetzt_content_uid',
  'active_shopStatuss': ['pos_03', 'pos_05', 'pos_07'],
  'payments': null,
  'takeaway': {nr:'00', deliverername:'Takeaway', state:'aktiv', seller_shopname:'', seller_uid:''},
  'shipper': {nr:'01', deliverername:'Shipper1', state:'aktiv', seller_shopname:'', seller_uid:''},
  'tax_de_1': 19,
  'tax_de_2': 7,
  'hours': ["10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
  'minus': ["00", "30"],
};

export const text1s: Record<string, string> =
{
  'titel': 'Restaurant',
  'domain': 'restaurant.jetzt',
  'chgdate': new Date().toISOString().slice(0, 10),
  'info0001': 'Der Vorgang ist erfolgreich abgeschlossen',
  'info0002': 'Prüfen Sie bitte Ihre Email',
  'info0003': 'Bestellung wird verarbeitet...',
  'info0004': 'Vielen Dank für Ihre Bestellung!',
  'info0005': 'Zahlungsvorgang',
  'info0006': 'Um die Waren zu bestellen, melden Sie sich als Käufer bzw. erstellen ein Käufer-Konto.',
  'info0007': 'Sie sind als Verkäufer angemedet!',
  'info0008': 'Email-Bestätigung',
  'info0009': 'Bitte Code hier eingeben',
  'erro0001': 'Die Eingabe ist ungültig',
  'erro0002': 'Der Vorgang ist fehlerhaft beendet',
  'erro0003': 'Straße, Hausnummer, Ort auswählen',
  'erro00033': 'Address liegt außerhalb des Servicebereichs',
  'link_agb1': '/shop/info/datenschutz/z5DVwAr2ktfZAObuyvpLVhU1MQu1',
  'info0011': 'Vielen Dank für Ihr Interesse!',
  'info0012': 'Wir haben Ihnen eine Bestätigungsmail an die von Ihnen angegebene Email-Adresse gesendet.',
  'info0013': 'Überprüfen Sie bitte Ihr Email-Postfach.',
  'info0014': 'Ein Mitarbeiter kommt.',
  'info0015': 'Der Tisch ist derzeit geschlossen. Wenden Sie sich bitte an unseren Service.',
  'info0016': 'Aktuell passenden Tisch nicht gefunden, auf Anfrage möglich!',
  'info0017': 'Anfragen',
  'info0018': 'Reservieren',
  'info0019': '🕗GESCHLOSSEN',
  'info0020': 'Tischbestellung',
  'info0021': '🕗GESCHLOSSEN - Vorbestellen möglich',
  'info0022': 'Zeitangabe erforderlich oder Bestellen an diesem Tag nicht möglich',
  'info0023': 'Absagen',
  'info0024': 'Soll die Reservierung storniert werden?',
  'info0025': 'Aktuell passenden Tisch nicht gefunden, neue Tischplanung möglich!',
  'info0026': 'Speichern',
  'info9999': 'Vielen Dank und mit freundlichen Grüßen!',
};
