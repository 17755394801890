import { Component, OnInit } from '@angular/core';
import { Seller } from '../../models/seller';
import { SellerService } from '../../services/seller.service';
import { systemSettings } from '../../../environments/environment';
import { first } from "rxjs/operators";


@Component({ 
    selector: 'carouselStartComponent', 
    templateUrl: 'carousel-start.component.html',
    styleUrls: ['../../styles.css'] 
})
export class CarouselStartComponent implements OnInit {

    seller: Seller;

    constructor(
        private sellerService: SellerService,
    ) { }

    async getUid() {
        return await this.sellerService.getSellerUid(systemSettings.operator);
    }

    ngOnInit() {

        this.getUid().then(seller => {
            this.sellerService.find(seller[0].uid).pipe(first()).subscribe(seller => this.seller = seller[0]);
        });

    }

}
