import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Showinfo } from './../models/showinfo';

@Component({
	selector: 'showinfo',
	templateUrl: './showinfo.component.html',
	styleUrls: ['./../styles.css']
})
export class ShowinfoComponent implements OnInit, OnDestroy {

	audio = new Audio();
	intervalId = null;
	counter = 0;

	constructor(
		public dialogRef: MatDialogRef<ShowinfoComponent>,
		@Inject(MAT_DIALOG_DATA) public showinfo: Showinfo) { }

	ngOnInit() {
		const title = sessionStorage.getItem('__restaurantjetzt__showinfo');
		if (title == this.showinfo.title) {
			this.dialogClose();
		}
		sessionStorage.setItem('__restaurantjetzt__showinfo', this.showinfo.title); 
		if (this.showinfo.audio) {
			try {
				this.audio.src = this.showinfo.audio;
        		this.audio.load();
			} catch (error) {
				console.log(error);
			}
			this.intervalId = setInterval(() => {
				this.playAudio();
			}, 20000)
		}
	}

	playAudio() {
        try {
            this.audio.play();
        } catch (error) {
			console.log(error);
			clearInterval(this.intervalId);
		}
		this.counter++;
		if (this.counter > 3) {
			clearInterval(this.intervalId);
			this.counter = 0;
		}
    }

	dialog_ok(): void {
		this.dialogRef.close(true);
	}

	dialog_cancel(): void {
		this.dialogRef.close(false);
	}

	dialogClose(): void {
		this.dialogRef.close(null);
	}

	ngOnDestroy(){
		sessionStorage.removeItem('__restaurantjetzt__showinfo');
		clearInterval(this.intervalId);
	}
}