<!--Carousel Wrapper-->
<div id="carousel-show-1z" class="carousel slide carousel-fade pt-4" data-ride="carousel">

    <!--Indicators-->
    <ol class="carousel-indicators">
        <li data-target="#carousel-show-1z" data-slide-to="0" class="active"></li>
        <li data-target="#carousel-show-1z" data-slide-to="1"></li>
        <li data-target="#carousel-show-1z" data-slide-to="2"></li>
    </ol>
    <!--/.Indicators-->

    <!--Slides-->
    <div class="carousel-inner" role="listbox">

        <!--First slide-->
        <div class="carousel-item active">
            <div class="view" style="background-image: url('../../assets/img/pageimg/bg.jpg'); background-repeat: no-repeat; background-size: cover;">

                <!-- Mask & flexbox options-->
                <div class="mask rgba-black-strong d-flex justify-content-center align-items-center">
                    <!-- Content -->
                    <div class="text-center white-text mx-5 wow fadeIn">
                        <img *ngIf="seller" src="{{seller.img[1]}}" class="img-profile-150 " alt="{{seller.shopname}}">
                        <h3 class="mb-4">
                            <strong>Demo...</strong>
                        </h3>
                        <p class="yellow-text">
                            <strong>Demo...</strong>
                        </p>
                        <h4>
                            <strong><a href="tel:0931123456789"><i class="fa fa-phone"></i> (0931) 123456789</a></strong>
                        </h4>
                    </div>
                    <!-- Content -->
                </div>
                <!-- Mask & flexbox options-->

            </div>
        </div>
        <!--/First slide-->

        <!--Second slide-->
        <div class="carousel-item">
            <div class="view" style="background-image: url('../../assets/img/pageimg/bg1.jpg'); background-repeat: no-repeat; background-size: cover;">

                <!-- Mask & flexbox options-->
                <div class="mask rgba-black-strong d-flex justify-content-center align-items-center">
                    <!-- Content -->
                    <div class="text-center white-text mx-5 wow fadeIn">
                        <img *ngIf="seller" src="{{seller.img[1]}}" class="img-profile-150 " alt="{{seller.shopname}}">
                        <h3 class="mb-4">
                            <strong>Demo...</strong>
                        </h3>
                        <p class="yellow-text">
                            <strong>Demo...</strong>
                        </p>
                        <h4>
                            <strong><a href="tel:0931123456789"><i class="fa fa-phone"></i> (0931) 123456789</a></strong>
                        </h4>
                    </div>
                    <!-- Content -->
                </div>
                <!-- Mask & flexbox options-->

            </div>
        </div>
        <!--/Second slide-->

        <!--Third slide-->
        <div class="carousel-item">
            <div class="view" style="background-image: url('../../assets/img/pageimg/bg2.jpg'); background-repeat: no-repeat; background-size: cover;">

                <!-- Mask & flexbox options-->
                <div class="mask rgba-black-strong d-flex justify-content-center align-items-center">
                    <!-- Content -->
                    <div class="text-center white-text mx-5 wow fadeIn">

                        <img *ngIf="seller" src="{{seller.img[1]}}" class="img-profile-150 " alt="{{seller.shopname}}">
                        <h3 class="mb-4">
                            <strong>Demo...</strong>
                        </h3>
                        <p class="yellow-text">
                            <strong>Demo...</strong>
                        </p>
                        <h4>
                            <strong><a href="tel:0931"><i class="fa fa-phone"></i> (0931) 123456789</a></strong>
                        </h4>
                    </div>
                    <!-- Content -->
                </div>
                <!-- Mask & flexbox options-->

            </div>
        </div>
        <!--/Third slide-->

    </div>
    <!--/.Slides-->

    <!--Controls-->
    <a class="carousel-control-prev" href="#carousel-show-1z" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel-show-1z" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
    <!--/.Controls-->

</div>
<!--/.Carousel Wrapper-->