import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { text1s, systemSettings } from '../../../environments/environment';
import { Seller } from '../../models/seller';
import { SellerService } from '../../services/seller.service';
import { DelivererService } from '../../services/deliverer.service';
import { MatDialog } from '@angular/material/dialog';
import { BusineService } from '../../services/busine.service';
import { first } from 'rxjs/operators';
import { Busine } from 'src/app/models/busine';

@Component({
	selector: 'registerSeller.component',
	templateUrl: 'registerSeller.component.html',
	styleUrls: ['../../styles.css']
})
export class RegisterSellerComponent implements OnInit {
	registerForm: FormGroup;
	loading = false;
	submitted = false;
	hide = true;
	isApprove = false;
	returnUrl = 'c/seller-detail';
	adminUid = '';
	busine: Busine;

	constructor(
		public dialog: MatDialog,
		private formBuilder: FormBuilder,
		private titleService: Title,
        private metaTagService: Meta,
		private router: Router,
		private authenticationService: AuthenticationService,
		private alertService: AlertService,
		private s: SellerService,
		private delivererService: DelivererService,
		protected activatedRoute: ActivatedRoute,
		private busineService: BusineService,
	) {

	}

	ngOnInit() {
		this.adminUid = this.s.getContentUid();
		this.check();
		this.registerForm = this.formBuilder.group({
			displayName: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required]
		});
		this.setMeta();
		this.activatedRoute.params.pipe(first()).subscribe((params) => {
            if (params) {
                this.getData(params.business_id);
            }
        });
		
	}

	getData(data_key: string){
		this.busineService.find(data_key).pipe(first()).subscribe(datas => {
			if (datas.length) {
				this.busine = datas[0];
			} else {
				this.router.navigateByUrl('');
			}
		});
	}

	async check() {
		const user = await this.authenticationService.getUser();
		const session = localStorage.getItem(window.location.hostname);
		let loggedIn = !!user;
		if (!loggedIn || !session) {

		} else {
			this.router.navigateByUrl('');
		}
	}

	async onSubmit() {
		this.submitted = true;
		this.alertService.clear();
		if (this.registerForm.invalid) {
			return;
		}
		this.loading = true;
		await this.authenticationService.createUserWithEmailAndPassword(this.f.email.value, this.f.password.value)
			.then(
				result => {
					if (!result.user?.emailVerified) {
						this.alertService.success(text1s.info0002);
						result.user?.sendEmailVerification();
						result.user?.updateProfile({
							displayName: this.f.displayName.value,
							photoURL: '',
						});
						setTimeout(() => {
							this.createSeller(result);
							this.router.navigate(['a/login']);
						}, 6000);
					}
				}).catch((err) => {
					this.alertService.error(err.message);
					this.loading = false;
				});
	}

	createSeller(i: any) {
		const seller = {
			shopname: this.f.displayName.value,
			description: this.f.displayName.value,
			email: i.user.email,
			uid: i.user.uid,
			img: ['', '', '', ''],
			infotext: this.f.displayName.value,
			shopStatus: 'pos_09',
			district: '',
			stripe_connect_account_id: '',
			stripe_accountLinks_url: '',
			paypal_connect_account_id: '',
			paypal_accountLinks_url: '',
			country: systemSettings.country,
			payments: null,
			auto_confirm: {isAuto: false, duration_option: '30'},
			webhooks: null,
			openings: [],
			domain: '',
			capacity: {place: 0, minute_reserv: 0, minute_person: 0},
		} as Seller;
		this.s.save(seller);

		let takeaway = systemSettings.takeaway;
		takeaway.seller_shopname = seller.shopname;
		takeaway.seller_uid = seller.uid;
		this.delivererService.save(takeaway);

		let shipper = systemSettings.shipper;
		shipper.seller_shopname = seller.shopname;
		shipper.seller_uid = seller.uid;
		this.delivererService.save(shipper);
	}

	setUrl(router: string) {
        return router + this.adminUid;
    }

	setMeta(): void {
        this.titleService.setTitle(`${text1s.domain} | Restaurant registrieren`);

        this.metaTagService.updateTag(
            { name: 'robots', content: 'noindex, nofollow' }
        );
        this.metaTagService.updateTag(
            { name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
        );
        this.metaTagService.updateTag(
            { name: 'keywords', content: `Restaurant registrieren` }
        );
        this.metaTagService.updateTag(
            { name: 'description', content: `${text1s.domain} Restaurant registrieren` }
        );
    }

	get f() { return this.registerForm.controls; }
}
