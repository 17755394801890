import { Component, Input } from '@angular/core';
import { CountdownEvent } from 'ngx-countdown';

@Component({
    selector: 'timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./../styles.css']
})
export class TimerComponent {

    xlefttime = 1;
    xClass = "blue-text";

    @Input() set min_sec(parm: {start: number, end: number}) {
        if (!isNaN(parm.start) && !isNaN(parm.end)) {
            this.xlefttime = Math.round((parm.end - parm.start) / 1000);
        } else {
            this.xlefttime = 1;
        }  
    }

    countdownHandleEvent(e: CountdownEvent) {
        if (e.action == 'done') {
            this.xClass = "blink_me red-text";
        }    
    }
}
