<div *ngIf="showinfo" class="my-1 text-muted">
    <h3 mat-dialog-title>{{showinfo.title}}</h3>
    <div mat-dialog-content>
        <div *ngFor="let text of showinfo.texts">
            <p *ngIf="text" [innerHTML]="text"></p>
        </div>
        <div class="text-center">
            <img *ngIf="!showinfo.img && !showinfo.isCancel" src="../../assets/img/pageimg/ok.png" class="img-profile">
            <img *ngIf="!showinfo.img && showinfo.isCancel" src="../../assets/img/pageimg/cancel.png" class="img-profile">
            <img *ngIf="showinfo?.img" src={{showinfo.img}} class="img-responsive-x-350">
        </div>
    </div>
    <div mat-dialog-actions>
        <button *ngIf="showinfo.isCancel" class="mx-2" mat-stroked-button color="primary" (click)="dialog_cancel()">Abbrechen</button>
        <button mat-stroked-button class="mx-2" color="primary" (click)="dialog_ok()">OK</button>
    </div>
</div>