<main class="mt-1 pt-4">
    <div class="container dark-grey-text mt-5">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-1">

                        <div class="p-1">
                            <form [formGroup]="loginForm" class="wt-form">
                                <fieldset class="border p-3">
                                    <legend class="w-auto text-muted"><small>Login</small></legend>
                                    <mat-form-field appearance="outline" class="wt-full-width">
                                        <input matInput type="email" formControlName="email" id="email" value="" placeholder="Email" matNativeControl required>
                                        <mat-icon matSuffix>email</mat-icon>
                                        <mat-hint></mat-hint>
                                        <mat-error *ngIf="f.email.hasError('required')"> Email ist erforderlich </mat-error>
                                        <mat-error *ngIf="f.email.hasError('email')"> Email ist ungültig </mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" class="wt-full-width">
                                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" id="password" value="" placeholder="Password">
                                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                        </button>
                                        <mat-error *ngIf="f.password.hasError('required')"> Password ist erforderlich </mat-error>
                                    </mat-form-field>
                                    <a (click)="onSubmit()" class="btn btn-primary btn-user btn-block"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>OK</a>
                                </fieldset>
                            </form>
                            <div class="text-center">
                                <a class="badge mdb-color lighten-4" (click)="clickPwReset()">Password vergessen ?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>