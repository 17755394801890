import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { text1s } from '../../../environments/environment';


@Component({
	selector: 'passwordReset.component',
	templateUrl: 'passwordReset.component.html',
	styleUrls: ['../../styles.css']
})
export class PasswordResetComponent implements OnInit {
	registerForm: FormGroup;
	loading = false;
	submitted = false;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private authenticationService: AuthenticationService,
		private alertService: AlertService
	) {

	}

	ngOnInit() {
		this.check();
		this.registerForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	async check() {
		const user = await this.authenticationService.getUser();
		const session = localStorage.getItem( window.location.hostname);
		let loggedIn = !!user;
		if (!loggedIn || !session) {

		} else {
			this.router.navigateByUrl('');
		}
	}

	get f() { return this.registerForm.controls; }

	async onSubmit() {
		this.submitted = true;
		this.alertService.clear();

		if (this.registerForm.invalid) {
			return;
		}
		this.loading = true;
		await this.authenticationService.sendPasswordResetEmail(this.f.email.value)
			.then(
				() => {
					this.alertService.success(text1s.info0002);
					setTimeout(() => {
						this.router.navigate(['a/login']);
					}, 6000);

				}
			).catch((err) => {
				this.alertService.error(err.message);
				this.loading = false;
			});
	}
}
